
<template>
    <div class="message" :class="{message_bottom:!isTrue}" style="height: 100%;overflow-y: auto;">
        <div class="top" style="height:40px">
            <van-sticky :offset-top="0">
                <div class="public_header">
                    <router-link to>
                        <van-icon @click="router_link" name="arrow-left" color="#fff" />
                    </router-link>
                    <span>站内信</span>
                    <span class="btn" v-if="isTrue" @click="transmit(isTrue)">编辑</span>
                    <span class="btn" v-else  @click="transmit(isTrue)">取消</span>
                </div>
            </van-sticky>
        </div>
        <!-- <div><van-button type="primary" @click="addmes()">添加</van-button></div> -->
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="height: calc(100% - 40px);overflow-y:auto;">
            <template #loading>
                <div style="height:0;"></div>
            </template>
            <div>
                <van-list
                    v-model="loading"
                    :finished="finished"
                    offset="50"
                    finished-text="没有更多了"
                    :error.sync="error"
                    error-text="请求失败，点击重新加载"
                    @load="onLoad"
                >
                    <!-- <router-link :to=" isTrue? '/messagedetail':''">
                        <van-cell>
                            <div class="message_list">
                                <div class="message_list_left">
                                    <van-checkbox v-if="!isTrue" v-model="checked"></van-checkbox>
                                    <van-icon name="envelop-o" v-if="true" />
                                    <span class="list_center">
                                        <div class="list_center_t">系统消息</div>
                                        <div class="list_center_b">此处显示站内信标题</div>
                                    </span>
                                </div>
                                <span class="list_time">2021/05/21</span>
                            </div>
                        </van-cell>
                    </router-link> -->
                    <van-cell v-for="item in list" :key="item.id" @click="toDetailed(item)">
                        <div class="message_list">
                            <div class="message_list_left">
                                <van-checkbox v-if="!isTrue" v-model="item.checked"></van-checkbox>
                                <van-icon name="envelop-o" v-if="item.is_read==0" />
                                <svg t="1623828887183" v-else class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1274" width="32" height="32"><path d="M956.032 340.48l-69.248-53.44L886.784 223.104c-0.704-19.712-8.768-38.144-22.656-52.096l-91.264-84.736c-14.4-12.608-33.088-19.968-52.224-20.544L580.224 65.728 560.704 51.072 560 50.688C544.96 40.96 528.32 37.312 512 37.248 495.616 37.312 478.976 40.96 463.936 50.688l-0.64 0.384L443.264 65.664 179.072 65.664C155.904 65.792 137.28 84.416 137.216 107.52l0 181.056L53.568 349.44 53.76 349.696C39.04 362.112 29.76 380.672 29.696 401.408l0 517.568c0.064 37.504 30.336 67.712 67.84 67.84l828.992 0c37.44-0.128 67.712-30.336 67.84-67.84L994.368 401.408C994.24 374.592 978.624 351.488 956.032 340.48zM886.72 363.328l10.624 8.192-10.624 6.912L886.72 363.328zM761.088 136l56.064 52.096-52.672 0c-1.664 0-3.392-1.6-3.392-3.328L761.088 136zM175.744 107.52c0-1.664 1.6-3.328 3.328-3.328l541.504 0c0.896-0.064 1.984 0 3.2 0.192-0.896 3.392-1.152 6.72-1.216 10.048l0 70.4c0.128 23.104 18.688 41.728 41.856 41.856l79.104 0c1.536 0 3.072-0.064 4.672-0.256l0 177.28L549.376 599.68C537.344 594.304 524.544 592.064 512 591.936 499.456 592.064 486.656 594.304 474.624 599.68l-298.88-196.032L175.744 107.52zM137.216 363.136l0 15.296L126.144 371.2 137.216 363.136zM89.92 419.584l328.704 215.552L89.92 850.56 89.92 419.584zM926.528 926.592 97.472 926.592c-2.816 0-5.44-1.92-6.656-4.416l406.208-266.24C499.264 654.208 505.472 652.16 512 652.288c6.464-0.128 12.672 1.856 14.976 3.584l406.144 266.24C931.904 924.608 929.344 926.592 926.528 926.592zM934.016 850.56 605.312 635.136l328.704-215.552L934.016 850.56zM748.224 376.32 275.968 376.32l0 32.704 472.256 0L748.224 376.32zM748.224 280.896 275.968 280.896l0 32.64 472.256 0L748.224 280.896z" p-id="1275" fill="#8d8d8d"></path></svg>
                                <span class="list_center">
                                    <!--  1：系统消息 2：交易信息 3：交易提醒-->
                                    <div class="list_center_t">{{item.type==1?'系统消息':(item.type==2?'交易信息':(item.type==1?'系统消息':'平台客服'))}}</div>
                                    <div class="list_center_b">{{item.title}}</div>
                                </span>
                            </div>
                            <span class="list_time">{{item.create_time.split(' ')[0]}}</span>
                        </div>
                    </van-cell>
                    <!-- <van-cell>
                        <div class="message_list">
                            <div class="message_list_left">
                                <van-checkbox v-if="!isTrue" v-model="checked"></van-checkbox>
                                <span class="list_center">
                                    <div class="list_center_t">系统消息</div>
                                    <div class="list_center_b">此处显示站内信标题</div>
                                </span>
                            </div>
                            <span class="list_time">2021/05/21</span>
                        </div>
                    </van-cell> -->
                </van-list>
            </div>
        </van-pull-refresh>
        <div class="fixd_btnlist"  v-if="!isTrue">
            <van-button class="readbtn" round type="info" @click="markRead()" size="large" color="linear-gradient(to right, #FF704D, #F73727)" native-type="submit">一键已读</van-button>
            <van-button round type="info" @click="delBtn()" color="linear-gradient(to right, #FF704D, #F73727)" size="large"  native-type="submit" :disabled="disabled">删除</van-button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Message',
    data() {
        return {
            isLoading:false,
            isTrue:true,
            active: 0,
            finished:false,
            error: false,
            loading:false,
            checked:false,
            list:[],
            page:1,
            deleteListId:'',
            updateList:[],
        }
    },
    computed: {
        disabled() {
            let disArr =  this.list.filter(item => {
                return item.checked == true
            });
            return disArr.length < 1
        }
    },
    created(){
        console.log(333)
        // console.log(window.history)
    },
    methods:{
        onRefresh(){
            this.error = false;
            this.loading = true;
            this.list = [];
            this.page = 1;
            this.finished = false;
            this.onLoad();
        },
        router_link(){
            if(window.history.length <= 2){
                this.$router.push({
                    name: 'Home'
                });
            }else{
                this.$router.back(-1);
            }
        },
        // 点击删除
        delBtn(){
            // console.log('d')
            this.deleteListId = '';
            this.updateList = [];
            this.list.forEach((item,index)=>{
                if(item.checked){
                    this.deleteListId+=item.id+','
                }else{
                    this.updateList.push(item)
                }
            })
            this.deleteListId = this.deleteListId.substr(0,this.deleteListId.length-1)
            // if (this.deleteListId == '') {
            //     this.$toast({
            //         message: '请先选择要删除的信息',
            //         duration: 1500
            //     });
            //     return false;
            // }
            let data = {id:this.deleteListId}
            this.$api.message.delete(data).then((res)=>{
                if(res.code==200){
                    this.list = this.updateList;
                    this.isTrue = true;
                    this.$toast({
                        message: '删除成功',
                        duration: 1500
                    });
                }
            })
        },
        // 点击一键已读
        markRead(){
            this.$api.message.allRead().then((res)=>{
                if(res.code==200){
                    this.isTrue = true;
                    this.list.forEach((item,index)=>{
                        this.list[index].is_read = 1;
                    })
                }
            })
        },
        // 跳转到详情页
        toDetailed(item){
            if(this.isTrue){
                console.log(item)
                this.$router.push({
                    path: '/messagedetail',
                    query: {
                        id: item.id
                    }
                })
            }
        },
        // 点击编辑||取消
        transmit(type){
            this.isTrue = !type;
        },
        addmes(){
            // content	是	string	内容
            // title	是	string	标题
            // type	是	int	消息类型 1：系统消息 2：交易信息 3：其他
            // link	否	string	链接 可以为空 在消息中显示的跳转链接
            let data = {
                title:"我是标题",
                content:"我是内容",
                type:2
            }
            this.$api.message.addMessage(data).then((res)=>{
                console.log(res)
            })
        },
        onLoad(){
            this.$api.message.getList({page:this.page}).then((res)=>{
                this.isLoading = false
                if (res.code == 200) {
                    if (res.data.length > 0) {
                        res.data.forEach((item, index, arr)=>{
                            res.data[index].checked = false
                        });
                        this.list = this.list.concat(res.data);
                        this.loading = false;
                        ++this.page;
                    }else{
                        //加载状态结束
                        this.loading = false;
                        //数据全部加载完成
                        this.finished = true;
                    }
                }else{
                    //加载状态结束
                    this.loading = false;
                    //错误提示
                    this.error = true;
                }
                
            })
        }
    },
    // watch: {
    //     $route: {
    //         handler(to,from) {
    //             //console.log(to, from);
    //             if(from){
    //                 if(to.name=='Message'){
    //                     this.onRefresh()
    //                 }
    //             }
    //         },
    //         deep:true, 
    //         immediate: true
    //     }
    // }
}
</script>
<style scoped>

.btn{
    position: absolute;
    top: 0;
    right: 3%;
}
.message_bottom{
    padding-bottom: 3.6875rem;
}
.message_list{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.message_list .message_list_left{
    display: flex;
    align-items: center;
}
.message_list .message_list_left .van-icon{
    color: #f73827;
    font-size: 26px;
    padding: 0 .71875rem;
}
.message_list .message_list_left svg{
    /* color: #227eeb; */
    width: 26px;
    padding: 0 .71875rem;
}
.message_list .list_center{
    max-width: 60%;
}
.message_list .list_center .list_center_t{
    font:600 .96875rem/1.125rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    color: #313131;
}
.message_list .list_center .list_center_b{
    font:.8125rem/1.125rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    color: #909090;
    max-width: 80%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.message_list_left{
    width: 79%;
}
.fixd_btnlist{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3.6875rem;
    background: #fff;
    display: flex;
    justify-content: space-around;
    border-top: 1px solid #dadada;
    align-items: center;
}
.fixd_btnlist .delbtn{
    background: #828282;
    border-color: #828282;
}
.fixd_btnlist button{
    width: 40%;
    height: 2.34375rem;
}
</style>